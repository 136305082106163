export function getCookie(cookieName) {
  var strCookie = document.cookie;
  var arrCookie = strCookie.split("; ");
  for (var i = 0; i < arrCookie.length; i++) {
    var arr = arrCookie[i].split("=");
    if (cookieName === arr[0]) {
      return arr[1];
    }
  }
  return "";
}

export function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

export function isToday(timestamp) {
  // 获取当前日期对象
  var currentDate = new Date();
  // 将时间部分设置为0，得到今天的开始时间戳
  var todayStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0
  ).getTime();

  return timestamp >= todayStart && timestamp < todayStart + 86400000;
}

export function formatTimestamp(timestamp, addWeekInfo) {
  // 创建一个新的日期对象
  var currentDate = new Date(timestamp);

  // 获取年、月和日
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1
  var day = ("0" + currentDate.getDate()).slice(-2);

  // 格式化日期为"YYYY-MM-DD"的格式
  var formattedDate = year + "-" + month + "-" + day;
  if (addWeekInfo) {
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    var weekDay = currentDate.getDay();
    var weekInfo = "星期" + week[weekDay];
    formattedDate = formattedDate + " " + weekInfo;
  }
  return formattedDate;
}

export function isWeekend(timestamp) {
  var currentDate = new Date(timestamp);
  var weekDay = currentDate.getDay();
  return weekDay === 0 || weekDay === 6;
}

export function stockDescReplace(text) {
  if (text != null) {
    return text.replace(
      /VOLUME_DEFAULT|VOLUME_INFLATION|VOLUME_TOO_LOW|VOLUME_SHRINK|NO_FISH|FISH_HEAD|FISH_BELLY|BIAS_RATIO_TOO_HIGH|BIAS_RATIO_TOO_LARGE|Capital Out|Capital In|MACD_DEATH_FAIL|MACD_GOLDEN_FAIL|MACD_GOLDEN|MACD_DEATH|sell|buy|/g,
      stockDescProcess
    );
  }
  return text;
}

function stockDescProcess(desc) {
  if (desc === "VOLUME_DEFAULT") {
    return "➡成交量正常";
  } else if (desc === "VOLUME_INFLATION") {
    return "⬆成交量放大";
  } else if (desc === "VOLUME_TOO_LOW") {
    return "⚠成交量过低";
  } else if (desc === "VOLUME_SHRINK") {
    return "⬇成交量缩小";
  } else if (desc === "NO_FISH") {
    return "🤦无鱼";
  } else if (desc === "FISH_HEAD") {
    return "🐟鱼头";
  } else if (desc === "FISH_BELLY") {
    return "鱼腹";
  } else if (desc === "BIAS_RATIO_TOO_HIGH") {
    return "⚠乖离率过高";
  } else if (desc === "BIAS_RATIO_TOO_LARGE") {
    return "⚠乖离率过高";
  } else if (desc === "Capital Out") {
    return "资金外流";
  } else if (desc === "Capital In") {
    return "资金流入";
  } else if (desc === "MACD_GOLDEN") {
    return "MACD金叉";
  } else if (desc === "MACD_DEATH") {
    return "MACD死叉";
  } else if (desc === "MACD_DEATH_FAIL") {
    return "MACD拒绝死叉";
  } else if (desc === "MACD_GOLDEN_FAIL") {
    return "MACD拒绝金叉";
  } else if (desc === "buy") {
    return "买入"
  } else if (desc === "sell") {
    return "卖出"
  }
  return desc;
}