import coverImg from "../../assets/stock_servant_cover.jpg";
import ReactMarkdown from "react-markdown";
import markdownUrl from "../../assets/intro.md";
import { useEffect, useState } from "react";

export function WelcomePage() {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    fetch(markdownUrl)
      .then((response) => response.text())
      .then((data) => setMarkdownContent(data));
  }, []);
  return (
    <div className="w-screen overflow-auto">
      <div className="w-full flex flex-col">
        <div className="w-screen flex items-center justify-center">
          <img src={coverImg} alt="stock servant cover" className="w-64" />
        </div>
        <div className="p-4">
          <ReactMarkdown
            children={markdownContent}
            className="text-left break-all w-full"
          />
        </div>
      </div>
    </div>
  );
}
