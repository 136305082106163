import { Modal, Input, App } from "antd";
import { useEffect, useState } from "react";
import { sendLoginRequest, sendRegisterRequest } from "../../repo/user";

export let openAccountModal = undefined;
export function configOpenAccountModal(func) {
  openAccountModal = func;
}

export function AccountModal({ onLoginSuc, onCancel: onLoginCancel }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [registerStyle, setRegisterStyle] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    if (registerStyle) {
      setUsername("");
      setEmail("");
      setPhone("");
      setPassword("");
    } else {
      setUsername("");
      setPassword("");
    }
  }, [registerStyle]);

  const login = () => {
    if (username === "" || password === "") {
      message.error("请填写账号密码");
      return;
    }
    setLoading(true);
    sendLoginRequest(username, password)
      .then((isSuc) => {
        console.log("login result: ", isSuc);
        if (!isSuc) {
          message.error("登录失败");
          return;
        }
        message.success("登录成功");
        onLoginSuc();
      })
      .catch((_) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const register = () => {
    if (username === "" || password === "" || email === "" || phone === "") {
      message.error("请填写账号信息");
      return;
    }
    if (!inviteCode.startsWith("noevil")) {
      message.error("邀请码错误");
      return;
    }
    setLoading(true);
    sendRegisterRequest(username, email, phone, password)
      .then((isSuc) => {
        console.log("register result: ", isSuc);
        if (!isSuc) {
          message.error("注册失败");
          return;
        }
        message.success("注册成功，请登录");
        setRegisterStyle(false);
      })
      .catch((_) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const loginContent = (
    <div className="flex flex-col">
      <div className="text-2xl font-bold mb-2">登录</div>
      <div className="mb-1">用户名</div>
      <Input
        className="text-base"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      ></Input>
      <div className="mt-2 mb-1">密码</div>
      <Input
        className="text-base"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      ></Input>
      <div
        className="mt-4 text-main underline select-none hover:cursor-pointer"
        onClick={() => setRegisterStyle(true)}
      >
        还没有账号？来注册一个吧!
      </div>
    </div>
  );
  const registerContent = (
    <div className="flex flex-col">
      <div className="text-2xl font-bold mb-2">注册</div>
      <div className="mb-1">用户名</div>
      <Input
        className="text-base"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      ></Input>
      <div className="mt-2 mb-1">邮箱</div>
      <Input
        className="text-base"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
      ></Input>
      <div className="mt-2 mb-1">电话</div>
      <Input
        className="text-base"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="tel"
      ></Input>
      <div className="mt-2 mb-1">密码</div>
      <Input
        className="text-base"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      ></Input>
      <div className="mt-2 mb-1">邀请码</div>
      <Input
        className="text-base"
        value={inviteCode}
        onChange={(e) => setInviteCode(e.target.value)}
      ></Input>
      <div
        className="mt-4 text-main underline select-none hover:cursor-pointer"
        onClick={() => setRegisterStyle(false)}
      >
        已有账号？直接登录吧!
      </div>
    </div>
  );
  return (
    <Modal
      onCancel={onLoginCancel}
      onOk={() => {
        if (registerStyle) {
          register();
        } else {
          login();
        }
      }}
      open
      centered
      confirmLoading={loading}
      okText={registerStyle ? "注册" : "登录"}
      cancelText="取消"
    >
      {!registerStyle && loginContent}
      {registerStyle && registerContent}
    </Modal>
  );
}
