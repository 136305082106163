import "./App.css";
import { ConfigProvider, App } from "antd";
import Homepage from "./pages/homepage";
import { GlobalStateProvider } from "./utils/globalState";

function MyApp() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#e67e22",
          cellPaddingBlock: 4,
        },
      }}
    >
      <GlobalStateProvider>
        <App>
          <Homepage />
        </App>
      </GlobalStateProvider>
    </ConfigProvider>
  );
}

export default MyApp;
