import { Layout, Menu } from "antd";

import { useMediaQuery } from "react-responsive";
import { PageList } from "../../config/pages";
import { NavLink, useLocation, Routes, Route } from "react-router-dom";
import { withRouter } from "../../utils/router";
import { useEffect, useState } from "react";
import { useLoginModal } from "../../utils/hooks";
import { AccountModal, configOpenAccountModal } from "../account";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.jpg";
import { getCookie } from "../../utils/common";
// 检查cookie中是否存在stockservant_token这个key
const hasStockServantToken = getCookie('stockservant_token');


const { Header, Sider, Content } = Layout;

export const DesktopScreen = ({ children }) => {
  return useMediaQuery({ minWidth: 901 }) ? children : null;
};

export const MobileScreen = ({ children }) => {
  return useMediaQuery({ maxWidth: 900 }) ? children : null;
};

function Homepage() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { isLoginModalOpen, setIsLoginModalOpen } = useLoginModal();
  const location = useLocation();
  const navigate = useNavigate();
  configOpenAccountModal(() => setIsLoginModalOpen(true));

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location]);

  return (
    <div>
      {isLoginModalOpen && (
        <AccountModal
          onCancel={() => {
            setIsLoginModalOpen(false);
            navigate("/");
          }}
          onLoginSuc={() => {
            setIsLoginModalOpen(false);
            window.location.reload();
          }}
        />
      )}
      <MobileScreen>
        <Header className="p-0 flex fixed w-full z-50">
          <NavLink
            className="text-white font-bold h-full flex text-base text-center items-center ml-2 select-none hover:cursor-pointer"
            to={"/"}
          >
            Stock Servant
          </NavLink>
          <MyMenu isMobilStyle={true} selectedKeys={selectedKeys} />
        </Header>
        <div className="min-h-max overflow-hidden flex pt-16">
          <div className="h-full overflow-hidden w-full">
            <div className="flex items-center flex-col w-full h-full overflow-auto">
              <MainContent />
            </div>
          </div>
        </div>
      </MobileScreen>
      <DesktopScreen>
        <Layout className="h-screen">
          <Sider>
            <NavLink
              className="text-white font-bold flex text-base  justify-center items-center mt-4 mb-2 select-none hover:cursor-pointer"
              to={"/"}
            >
              <div className="flex flex-col items-center justify-center">
                <img src={logo} alt="logo" className="h-16 w-16 rounded-xl" />
                <div className="text-center mt-2">Stock Servant</div>
              </div>
            </NavLink>

            <MyMenu isMobilStyle={false} selectedKeys={selectedKeys} />
            {!hasStockServantToken && (
                <div onClick={() => setIsLoginModalOpen(true) }  className="text-white font-bold h-full flex items-bottom text-base ml-2 select-none hover:cursor-pointer"
                        style={{ background: 'none', position: 'absolute', left: '15px'}} >
                    Sign in
                </div>
            )}
          </Sider>
          <Content className="bg-light-main flex">
            <MainContent />
          </Content>
        </Layout>
      </DesktopScreen>
    </div>
  );
}

const MyMenu = ({ isMobilStyle, selectedKeys }) => {
  const selectedKey =
    PageList.find((item) => item.path === selectedKeys[0])?.key ?? "home";
  return (
    <Menu
      className="w-full text-white"
      theme="dark"
      mode={isMobilStyle ? "horizontal" : "vertical"}
      selectedKeys={[selectedKey]}
      items={PageList.filter((item) => item.asMenuItem).map((item) => {
        return {
          key: item.key,
          label: <NavLink to={item.path}>{item.name}</NavLink>,
        };
      })}
    />
  );
};

const MainContent = () => {
  return (
    <Routes>
      {PageList.map((route) => {
        return (
          <Route
            exact
            path={route.path}
            key={route.path}
            element={route.component}
          ></Route>
        );
      })}
    </Routes>
  );
};

export default withRouter(Homepage);
