import { useGlobalState } from "./globalState";

export const useLoginModal = () => {
  const { globalState, setGlobalState } = useGlobalState();
  const isLoginModalOpen = globalState.isLoginModalOpen;

  const setIsLoginModalOpen = (isLoginModalOpen) => {
    setGlobalState({ isLoginModalOpen });
  };

  return {
    isLoginModalOpen,
    setIsLoginModalOpen,
  };
};
