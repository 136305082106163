import { API_DOMAIN } from "../config/config";
import { openAccountModal } from "../pages/account";
import { getCookie } from "../utils/common";

export async function getWithAuth(url) {
  const token = getCookie("stockservant_token");
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json, text/plain, */*");
  myHeaders.append("stockservant_token", token);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const response = await fetch(`${API_DOMAIN}${url}`, requestOptions);
  const result = await response.json();
  console.log(result);
  if (result.status === 403) {
    openAccountModal?.();
    console.log("auth failed");
  }
  return result;
}

export async function postJson(url, body) {
  const resp = await fetch(`${API_DOMAIN}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const result = await resp.json();
  return result;
}

export async function putJson(url, body) {
  const resp = await fetch(`${API_DOMAIN}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const result = await resp.json();
  return result;
}

export async function postJsonWithAuth(url, body) {
    const token = getCookie("stockservant_token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json")
    const resp = await fetch(`${API_DOMAIN}${url}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    });
    const result = await resp.json();
    return result;
  }


export async function putWithAuth(url, body) {
    const token = getCookie("stockservant_token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json")
    const resp = await fetch(`${API_DOMAIN}${url}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(body),
    });
    const result = await resp.json();
    return result;
}

export async function deleteWithAuth(url, body) {
    const token = getCookie("stockservant_token");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("stockservant_token", token);
    myHeaders.append("Content-Type", "application/json")
    const resp = await fetch(`${API_DOMAIN}${url}`, {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(body),
    });
    const result = await resp.json();
    return result;
}
